import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"

import {
  FaUserFriends,
  FaConciergeBell,
  FaEnvelope,
  FaImages,
} from "react-icons/fa"

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="md" id="site-navbar" fixed="top">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span" className="navbar-brand">
            Green Velvet
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/about" className="link-no-style">
              <Nav.Link as="span" eventKey="about" className="link">
                <FaUserFriends className="icon" />
                About
              </Nav.Link>
            </Link>
            <Link to="/services" className="link-no-style">
              <Nav.Link as="span" eventKey="services" className="link">
                <FaConciergeBell className="icon" />
                Services
              </Nav.Link>
            </Link>
            <Link to="/gallery" className="link-no-style">
              <Nav.Link as="span" eventKey="gallery" className="link">
                <FaImages className="icon" />
                Gallery
              </Nav.Link>
            </Link>
            <Link to="/contact" className="link-no-style">
              <Nav.Link as="span" eventKey="contact" className="link">
                <FaEnvelope className="icon" />
                Contact
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
