import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"
import Navbar from "./navBar"

import logo from "../images/gv_logo1.png"

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Navbar pageInfo={pageInfo} />
          <Container className="jumbo-logo">
            <Row noGutters>
              <Col>
                <Image src={logo} fluid rounded />
              </Col>
            </Row>
          </Container>
          <Row noGutters>
            <Col>
              <main>{children}</main>
            </Col>
          </Row>
          <Row>
            <Col className="signature">
              <h5>with gratitude,</h5>
              <h3>Bebe + Joy</h3>
              <h5>Telluride | CO</h5>
            </Col>
          </Row>
          <Row>
            <Col className="signature">
              <h5>
                Phone
                <br />
                <a href="tel:9703699219">970-369-9219</a>
              </h5>
            </Col>
            <Col className="signature">
              <h5>
                Email
                <br />
                <a href="mailto:hello@greenvelvettelluride.com">
                  hello@greenvelvettelluride.com
                </a>
              </h5>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <footer>
                <span>
                  © {new Date().getFullYear()} Green Velvet Events LLC
                  <br />
                  <small>
                    Built by
                    {` `}
                    <a href="https://www.busterdoes.com">Buster</a>
                  </small>
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default Layout
